import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Container = styled.aside`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 150px;
  height: 100%;
  padding: 10px;
  background-image: linear-gradient(to bottom right, #009cde, #0073cc);
  flex-direction: column;

  transition: all 0.2s ease 0s;

  img {
    max-width: 90%;
    margin-bottom: 20px;
  }

  &:hover {
    width: 250px;
  }
`;

export const SidebarLink = styled(NavLink)`
  width: 100%;
  text-align: center;
  text-decoration: none;
  padding: 15px;
  color: #eee;
  margin-top: 3px;
  background-color: #3cadea;

  &:hover {
    color: #fff;
    background-color: #0073cc;
  }
`;
