import styled from "styled-components";

export const Container = styled.div``;
export const Form = styled.form`
  .errors-endereco {
    color: red;
  }
  input {
    padding: 10px;
    margin: 5px;
    border: 1px solid #555;
    border-radius: 4px;
    display: block;
    width: 98%;
  }
`;
