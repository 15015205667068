export const TOKEN_KEY = "@_Tkn";
export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const dataUser = () => isAuthenticated() ? 
  JSON.parse(atob(sessionStorage.getItem(TOKEN_KEY).split(".")[1])): [];
export const login = (token) => {
  if (token) {
    sessionStorage.setItem(TOKEN_KEY, token);
  } else {
    sessionStorage.removeItem(TOKEN_KEY);
    sessionStorage.removeItem("SPCad");
    sessionStorage.removeItem("SPUser");
    sessionStorage.removeItem("SPFin");
    localStorage.removeItem("SPCheckout");
    window.location.reload();
  }
};
export const logout = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem("SPCad");
  sessionStorage.removeItem("SPUser");
  sessionStorage.removeItem("SPFin");
  localStorage.removeItem("SPCheckout");
};
