import React, { useState } from "react";
import { PedResumo } from "./styles";
import {
  moeda,
  databoa,
  iconOrderSelector,
  iconSelector,
} from "../../../services/utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formas from "../../../services/formas_pagamento.json";

import {
  faDolly,
  faCheck,
  faShippingFast,
  faHandHoldingUsd,
  faShoppingBasket,
  /* faTimes, */
  faBarcode,
  faDownload,
  faInfo,
  faWallet,
  faCreditCard,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { notificar } from "../../../components/Notificador";
import GeraPagamento from "../../../components/GeraPagamento";
import api from "../../../services/api";
import { Recado } from "../../../components/styled-components-global";
import GeraPix from "../../../components/GeraPix";

export default function OrderDetails({ pedido, detalhes, mensagem }) {
  const [showPix, setShowPix] = useState(false);
  //TODO
  /* const handleExcluiPedido = (e, id) => {
    notificar(`Excluindo pedido ${id}.`, "vermelho", "embaixo");
  }; */
  /* if (mensagem) {
    notificar(mensagem.msg, mensagem.cor, null, null, null, 10000);
  } */

  const handleDadosDeposito = () => {
    notificar(
      "Você receberá um email em breve com nossos dados bancários para depósito."
    );

    api.get("pagamentos/dadosbancarios").then((ret) => {
      let cor = !ret.data.error ? "verde" : "vermelho";
      notificar(ret.data.msg, cor);
    });
  };

  const handlePacote = (id) => {
    notificar(`Preparando seu download...`, null, null, null, null, 2000);
    api
      .post("empacotar", { id }, { responseType: "arraybuffer" })
      .then((response) => {
        if (response.status === 204) {
          notificar(
            `Desculpe. Este pedido não possui fotos para download.`,
            "vermelho"
          );
          return 0;
        } else {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `Pedido-${id}.zip`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
      .catch((err) => {
        notificar(`Um erro ocorreu. Desculpe. ${err}`);
      });
  };

  return (
    <PedResumo>
      {mensagem && <Recado>{mensagem.msg}</Recado>}
      <div className="content">
        <span>
          Você está vendo o pedido número <strong>{pedido.pedId}</strong>
        </span>
        <span className="order-details">
          {pedido.pedTotal - pedido.pedDesconto !== 0
            ? moeda(pedido.pedTotal - pedido.pedDesconto)
            : "Crédito"}
        </span>
      </div>
      <span className="order-state" title="Histórico deste pedido.">
        {iconOrderSelector("pedido", faShoppingBasket, pedido, false)}
        {iconOrderSelector("financeiro", faHandHoldingUsd, pedido, false)}
        {iconOrderSelector("processamento", faDolly, pedido, false)}
        {iconOrderSelector("ok", faShippingFast, pedido, false)}
        {iconOrderSelector("entregue", faCheck, pedido, false)}
      </span>
      <table id="order-details">
        <thead>
          <tr>
            <th>#</th>
            <th>Ítem</th>
            <th>Tipo</th>
            <th>Quantidade</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {detalhes.cart.map((it) => (
            <tr key={it.idCart} title={it.detalhes.descricao}>
              <td>
                <img src={it.caminho} />
              </td>
              <td>{it.detalhes.nome}</td>
              <td>{it.tipo}</td>
              <td style={{ textAlign: "right" }}>{it.qt}x</td>
              <td style={{ textAlign: "right" }}>{moeda(it.detalhes.valor)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          {(detalhes.checkout[0].frete && detalhes.checkout[0].frete.length && (
            <tr title={detalhes.checkout[0].frete[0].descricao}>
              <td></td>
              <td style={{ textAlign: "right" }}>
                Tipo de frete escolhido: {detalhes.checkout[0].frete[0].nome}
              </td>
              <td></td>
              <td style={{ textAlign: "right" }}>Frete:</td>
              <td style={{ textAlign: "right" }}>
                {moeda(detalhes.checkout[0].frete[0].valor)}
              </td>
            </tr>
          )) ||
            null}
          <tr>
            <td></td>
            <td style={{ textAlign: "right" }}>
              {pedido.pedRastreio && (
                <span>
                  Rastreamento da entrega: <strong>{pedido.pedRastreio}</strong>
                </span>
              )}
            </td>
            <td></td>
            <td style={{ textAlign: "right" }}>Subtotal:</td>
            <td style={{ textAlign: "right" }}>
              {moeda(detalhes.checkout[0].bruto)}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: "right" }}>Descontos:</td>
            <td style={{ textAlign: "right" }}>
              {moeda(detalhes.checkout[0].descontos.total)}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: "right" }}>
              <strong>Total:</strong>
            </td>
            <td style={{ textAlign: "right" }}>
              <strong>{moeda(detalhes.checkout[0].liquido)}</strong>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: "right" }}>Pagamento:</td>
            <td style={{ textAlign: "right" }}>
              {iconSelector(pedido.pedPagamento.parcforma, formas)}{" "}
              {
                formas[
                  pedido.pedPagamento.parcforma === 100
                    ? 0
                    : pedido.pedPagamento.parcforma
                ].forma
              }
            </td>
          </tr>
        </tfoot>
      </table>
      {showPix && (
        <GeraPix
          dados={pedido.pedPagamento}
          mensagem="ATENÇÃO: Só faça o pagamento se tiver certeza de não o ter feito antes. Nosso sistema pode levar até 72hs para confirmar seu PIX."
          onClick={(e) => e.stopPropagation()}
        />
      )}
      <div className="actions">
        {/* {pedido.pedFinanceiro === null && (
          <FontAwesomeIcon
            className="icon-ped-act"
            icon={faTimes}
            title="Pedidos que ainda não foram pagos podem ser excluídos"
            onClick={(e) => handleExcluiPedido(e, pedido.pedId)}
          />
        )} */}
        {(pedido.pedFinanceiro !== null ||
          pedido.pedTotal - pedido.pedDesconto === 0) && (
          <button
            className="ped-act"
            onClick={() => handlePacote(pedido.pedId)}
          >
            <FontAwesomeIcon
              className="icon-ped-act"
              icon={faDownload}
              title="Fazer download das imagens deste pedido"
            />
            Fazer download das imagens
          </button>
        )}
        {pedido.pedFinanceiro === null && pedido.pedPagamento.parcforma === 4 && (
          <button className="ped-act" onClick={() => handleDadosDeposito()}>
            <FontAwesomeIcon
              className="icon-ped-act"
              icon={faWallet}
              title="Dados para depósito"
            />
            Dados para depósito
          </button>
        )}
        {pedido.pedPagamento.parcforma === 2 &&
          pedido.pedPagamento.parctransacao &&
          pedido.pedPagamento.parcpagoem === null && (
            <a
              href={pedido.pedPagamento.parctransacao}
              rel="noopener noreferrer"
              className="ped-act"
            >
              <FontAwesomeIcon
                className="icon-ped-act"
                icon={faCreditCard}
                title="Pagar este pedido agora"
              />{" "}
              Pagar agora
            </a>
          )}
        {pedido.pedPagamento.parcforma === 6 &&
          pedido.pedPagamento.parctransacao &&
          pedido.pedPagamento.parcpagoem === null && (
            <button className="ped-act" onClick={() => setShowPix(!showPix)}>
              <FontAwesomeIcon
                className="icon-ped-act"
                icon={faQrcode}
                title="Pagar este pedido agora"
              />{" "}
              Exibir PIX
            </button>
          )}

        {pedido.pedFinanceiro === null &&
          pedido.pedPagamento.parcforma === 5 && (
            <GeraPagamento
              montar={pedido.pedPagamento}
              icone={faBarcode}
              via={2}
              texto={"Ver boleto"}
              forma={5}
              className="ped-act"
            />
          )}
      </div>
    </PedResumo>
  );
}
