import React from "react";

import { Container, Title } from "./styles";

function Main() {
  return (
    <Container>
      <Title>Main</Title>
    </Container>
  );
}

export default Main;
