import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  table {
    width: 100%;
    border: 1px solid #555;
    padding: 0;
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;
    tbody {
      tr {
        td {
          border: 1px solid #555;
          padding: 5px;
        }
      }
    }
    tfoot {
      tr {
        td {
          text-align: right;
          font-size: 0.8rem;
          padding: 5px;
        }
      }
    }
  }
`;
