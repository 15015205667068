import React from "react";

import { Container, SidebarLink } from "./styles";
import logo from "./../../../assets/logoSP.png";

function Sidebar() {
  return (
    <Container>
      <img alt="" src={logo} />
      <SidebarLink to="/sistema">Início</SidebarLink>
      <SidebarLink to="/contact">Contato</SidebarLink>
      <a href="http://localhost/sysmeiden2.0">Menu principal</a>
    </Container>
  );
}

export default Sidebar;
