import styled from "styled-components";

export const ProdList = styled.section`
  @media (min-width: 480px) {
    /* smartphones, Android phones, landscape iPhone */
    column-count: 1;
  }
  @media (min-width: 600px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    column-count: 2;
  }
  @media (min-width: 801px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
    column-count: 3;
  }
  @media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
    column-count: 4;
  }
  @media (min-width: 1281px) {
    column-count: 5;
  }
  --view-header: none;
  --margin-img: 0;
  column-gap: 0.5em;
  row-gap: 0.5em;

  Loader {
    margin: 10% auto;
  }
`;

export const ProdItem = styled.article`
  display: inline-block;
  border: 1px solid transparent;
  margin: 0;
  margin-top: 0;
  width: 100%;
  overflow-y: hidden;

  :hover {
    border: 1px solid #7159c1;
    --view-header: flex;
    --margin-img: -43px;
  }

  header {
    padding: 10px;
    width: 100%;
    position: relative;
    display: var(--view-header);
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    background-color: rgba(92, 144, 221, 0.5);

    button {
      background: transparent;
      border: 0;
      cursor: pointer;
    }

    span {
      color: #fff;
    }

    button img {
      height: 20px;
    }
  }

  img.img-album {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: -8px;
    margin-top: var(--margin-img);
  }

  .mask {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 134, 195, 0.8);
    box-shadow: 5px 4px 5px #000000a1;
    z-index: 20;
    overflow: hidden;
    color: #fff;

    ul#mnuBit,
    ul#mnuCompl,
    div#mnuConfCompl,
    div#mnuProdCompl,
    div#mnuQt,
    div#msg_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      overflow-y: auto;
      padding: 20px;

      span {
        padding: 10px;
        margin: 30px auto;
      }

      p {
        max-width: 300px;
      }
    }
  }
  .bit_item {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    border-top: 1px solid #fff;

    :last-child {
      border-bottom: 1px solid #fff;
    }

    button {
      background-color: transparent;
      cursor: pointer;
      border: none;
      color: white;

      width: 100%;
      text-align: center;
      padding: 10px;
    }
  }

  .bit_item:hover,
  .qt_item:hover,
  .bt_alpha:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .qt_item::placeholder {
    color: white;
    font-size: 0.8em;
  }

  .bt_alpha {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;

    height: 40px !important;
    font-size: 1.3em;
    text-align: center;
    color: white;
    cursor: pointer;
    width: 100%;
  }

  .qt_item {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }

  .icon32 {
    width: 32px;
  }

  .icon24 {
    width: 24px;
  }
`;
