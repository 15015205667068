import React from "react";

import { Container } from "./styles";
import { moeda, databoa } from "../../../services/utils";

function RelatorioPagamentos({ dados, formatura }) {
  return (
    <Container>
      <h2>Relatório de pagamentos para esta turma</h2>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Pago</th>
          </tr>
        </thead>
        <tbody>
          {dados.financeiro.map((dt) => (
            <tr key={dt.AlCodigo}>
              <td>{dt.AlCodigo}</td>
              <td>{dt.AlNome}</td>
              <td>
                {dt.AlConta !== null && dt.AlConta.hasOwnProperty("AlParcelas")
                  ? moeda(
                      dt.AlConta.AlParcelas.reduce(
                        (a, c) => a + c.parcvalorpago,
                        0
                      )
                    )
                  : "Nenhum pagamento"}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3}>
              Valor do contrato: {moeda(formatura.FormValorPrazo)}
              {formatura.FormReajuste > 0 &&
                ` - Reajuste: ${moeda(formatura.FormReajuste)}`}{" "}
              - Gerado {databoa(null, true)}
            </td>
          </tr>
        </tfoot>
      </table>
    </Container>
  );
}

export default RelatorioPagamentos;
