import React, { useState } from "react";
import { handleSaveCache } from "../services/utils";

let localCart = [];

let sessionUser = [];

let sessionPedidos = [];

let sessionCad = [];

let sessionFin = [];

let localCheckout = {
  frete: {},
  descontos: [],
  bruto: 0,
  liquido: 0,
};

localCart = sessionStorage.getItem("SPCart")
  ? JSON.parse(sessionStorage.getItem("SPCart"))
  : [];

sessionUser = sessionStorage.getItem("SPUser")
  ? JSON.parse(sessionStorage.getItem("SPUser"))
  : [];

sessionPedidos = sessionStorage.getItem("SPPedidos")
  ? JSON.parse(sessionStorage.getItem("SPPedidos"))
  : [];

sessionCad = sessionStorage.getItem("SPCad")
  ? JSON.parse(sessionStorage.getItem("SPCad"))
  : [];

sessionFin = sessionStorage.getItem("SPFin")
  ? JSON.parse(sessionStorage.getItem("SPFin"))
  : [];

localCheckout = localStorage.getItem("SPCheckout")
  ? JSON.parse(localStorage.getItem("SPCheckout"))
  : {
      frete: {},
      descontos: [],
      bruto: 0,
      liquido: 0,
    };

const initialState = {
  cart: localCart,
  user: sessionUser,
  pedidos: sessionPedidos,
  cadastro: sessionCad,
  financeiro: sessionFin,
  checkout: localCheckout,
  header: true,
};

export const StateContext = React.createContext({
  state: initialState,
  actions: {
    setCart: () => {},
    setUser: () => {},
    setPedidos: () => {},
    setCadastro: () => {},
    setFinanceiro: () => {},
    setCheckout: () => {},
    setPerfil: () => {},
    setHeader: () => {},
  },
});

const setPerfil = (state, setState, perfil) => {
  const { financeiro, cadastro, pedidos } = perfil;
  setState({ pedidos, cadastro, financeiro });
  sessionStorage.setItem("SPPedidos", JSON.stringify(pedidos));
  sessionStorage.setItem("SPCad", JSON.stringify(cadastro));
  sessionStorage.setItem("SPFin", JSON.stringify(financeiro));
};

const setCart = (state, setState, cart) => {
  localStorage.setItem("SPCart", JSON.stringify(cart));
  setState({ cart });
  handleSaveCache("cart", cart);
};

const setUser = (state, setState, user) => {
  sessionStorage.setItem("SPUser", JSON.stringify(user));
  setState({ user });
};

const setPedidos = (state, setState, pedidos) => {
  sessionStorage.setItem("SPPedidos", JSON.stringify(pedidos));
  setState({ pedidos });
};

const setCadastro = (state, setState, cadastro) => {
  sessionStorage.setItem("SPCad", JSON.stringify(cadastro));
  setState({ cadastro });
};

const setFinanceiro = (state, setState, financeiro) => {
  sessionStorage.setItem("SPFin", JSON.stringify(financeiro));
  setState({ financeiro });
};

const setCheckout = (state, setState, checkout) => {
  localStorage.setItem("SPCheckout", JSON.stringify(checkout));
  setState({ checkout });
};
const setHeader = (state, setState, header) => {
  setState({ header });
};

export const Context = (props) => {
  const [state, _setState] = useState(initialState);

  const setState = (_state) => {
    const newState = { ...state, ..._state };
    _setState(newState);
  };

  const actions = {
    setCart: setCart.bind(null, state, setState),
    setPerfil: setPerfil.bind(null, state, setState),
    setUser: setUser.bind(null, state, setState),
    setPedidos: setPedidos.bind(null, state, setState),
    setCadastro: setCadastro.bind(null, state, setState),
    setFinanceiro: setFinanceiro.bind(null, state, setState),
    setCheckout: setCheckout.bind(null, state, setState),
    setHeader: setHeader.bind(null, state, setState),
  };
  return (
    <StateContext.Provider value={{ state, actions }}>
      {props.children}
    </StateContext.Provider>
  );
};
