import React, { useContext } from "react";
import { moeda, refreshFrete } from "../../../services/utils";
import { StateContext } from "../../../context/Context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import { Container } from "./styles";

function Resumo({ cart, checkout }) {
  const context = useContext(StateContext);

  return (
    <Container>
      <h3>
        <FontAwesomeIcon icon={faFlagCheckered} /> Resumo:
      </h3>
      <p>
        {" "}
        Você tem {cart.length} produto
        {cart.length > 1 && <span>s</span>} no pedido
      </p>
      <p>
        Total:{" "}
        {moeda(cart.reduce((a, v) => a + v.detalhes.valor * parseInt(v.qt), 0))}
      </p>
      {checkout.frete.length > 0 &&
        checkout.frete[0].valor > 0 &&
        refreshFrete(cart) && <p>Frete: {moeda(checkout.frete[0].valor)}</p>}
    </Container>
  );
}

export default Resumo;
