import styled from "styled-components";

export const Corpo = styled.div`
  /*background: #7159c1;*/
  background: #edf7ff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  margin: 30px;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
`;
