import React, { Component } from "react";
import { stringToList } from "../../services/utils";

//dados
import uuid from "react-uuid";
import api from "../../services/api";
import { StateContext } from "./../../context/Context";

//interface do usuario
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Recado } from "../../components/styled-components-global";
import { ProdList, ProdItem } from "./styles";
import { notificar } from "../../components/Notificador";

//icones
import shop from "../../assets/icons/svg/flat/045-basket-white.svg";
import trash from "../../assets/icons/svg/flat/garbage-2-white.svg";
import params from "../../assets/icons/svg/flat/controls.svg";
import help from "../../assets/icons/svg/flat/046-customer-service.svg";

//inativos temporariamente
/* import more from "../../assets/icons/svg/flat/more.svg"; */
import Tour from "reactour";
import t1 from "../../assets/tour-produto-1.png";
import t2 from "../../assets/tour-produto-2.png";
import t3 from "../../assets/tour-produto-3.png";

const steps = [
  {
    selector: `[data-tour="tour-ini"]`,
    content: () => (
      <div>
        <h3>Novo por aqui? Siga estas dicas.</h3>
        <div className="tour-init">
          <img src={help} alt="Ajuda" />
          <p>
            Este é um pequeno tutorial de como fazer seus pedidos. <br />
            Não precisa de ajuda agora? Clique fora desse aviso para ver depois.
          </p>
        </div>
      </div>
    ),
  },
  {
    selector: `[data-tour="tour-0"]`,
    content: () => (
      <div className="tour-content">
        <h4>Selecionar produtos para um pedido</h4>
        <p>
          Você deve clicar em configurações{" "}
          <img
            style={{ width: 20, marginBottom: -5, marginLeft: 5 }}
            src={params}
            alt="Ajuda"
          />{" "}
          para escolher seu produto.
        </p>
        <img src={t1} alt="Ajuda" />
      </div>
    ),
  },
  {
    selector: `[data-tour="tour-1"]`,
    content: () => (
      <div className="tour-content">
        <h4>Ajustar a quantidade</h4>
        <p>
          Aqui você define quantas unidades desse produto você quer em seu
          pedido...
        </p>
        <img src={t2} alt="Ajuda" />
      </div>
    ),
  },
  {
    selector: `[data-tour="tour-2"]`,
    content: () => (
      <div className="tour-content">
        <h4>Colocar no pedido</h4>
        <p>
          ...e, ao clicar na cesta, você coloca o ítem configurado no pedido.
        </p>
        <img src={t3} alt="Guardar" />
      </div>
    ),
  },
  // ...
];
class Produtos extends Component {
  static contextType = StateContext; // chave de acesso ao contexto

  state = {
    feed: [],
    slug: "produto",
    qt: 1,
    isLoading: true,
    mask_view: false,
    view_mnu_quantidade: false,
    imgPrv: "",
    prvShow: false,
    ignorarCreditos: false,
    isTourOpen: localStorage.getItem("tour-produto") ? false : true,
  };

  async componentDidMount() {
    if (this.context.state.cadastro.length === 0) {
      this.setState({
        cadastro: JSON.parse(sessionStorage.getItem("SPCad")),
      });
    } else {
      this.setState({ cadastro: this.context.state.cadastro });
    }

    await api
      .get(`/produtos/listar`)
      .then((response) => {
        this.setState({
          feed: response.data,
          isLoading: false,
          isLoading_item: true,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.responderEnd);
        }
      });
  }

  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  handleConfig(id) {
    this.setState({
      mask_view: id,
      view_mnu_quantidade: true,
      isLoading_item: false,
    });
  }

  backAddCart = () => {
    this.setState({
      view_mnu_medidas: true,
      view_mnu_quantidade: false,
    });
  };

  handleAddCart = (prod) => {
    const prodCart = { ...prod };
    prodCart.idCart = uuid();
    prodCart.slug = this.state.slug;
    prodCart.qt = parseInt(this.state.qt);
    prodCart.tipo = "produto";
    prodCart.tamanho = 0;
    prodCart.detalhes = {
      descricao: prodCart.descricao,
      nome: prodCart.nome,
      slug: prodCart.slug,
      tamanho: prodCart.tamanho,
      valor: prodCart.valor,
      complementares: [],
    };

    this.context.actions.setCart([prodCart, ...this.context.state.cart]);
    this.setState({
      view_mnu_quantidade: false,
      qt: 1,
      slug: null,
    });
    notificar(
      ({ closeToast }) => (
        <p>
          Ítem adicionado.{" "}
          <button
            className="bt-toast"
            onClick={() => this.props.history.push("/cart")}
          >
            Ver carrinho?
          </button>
        </p>
      ),
      /* `${prodCart.id} - ${prodCart.detalhes.nome} adicionado ao carrinho`, */
      "verde",
      "embaixo"
    );
  };

  cancelAddCart = () => {
    this.setState({
      view_mnu_quantidade: false,
      mask_view: false,
      qt: 1,
      slug: null,
    });
  };

  handleChange = (event) => {
    this.setState({
      qt: event.target.value,
    });
  };

  handleEscape = (event) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.setState({
        view_mnu_quantidade: false,
        mask_view: false,
        qt: 1,
        slug: null,
      });
    }
  };

  handlePreview = (props) => {
    this.setState({
      prvShow: !this.state.prvShow,
    });
    return <img src={props.img} alt="Preview de foto" />;
  };

  handleHelp = () => {
    this.setState({ isTourOpen: true });
  };

  render() {
    return (
      <React.Fragment>
        {/* { this.state.prvShow && <Mask imagem={this.state.imgPrv}></Mask> } */}
        <div className="container-feed">
          <Recado>
            <p>Passe o mouse sobre os produtos para ver as opções. </p>
            <button
              style={{
                width: 42,
                height: 42,
                padding: 2,
                marginLeft: 10,
                borderRadius: 100,
              }}
              className="icone-e-texto"
              title="Precisa de ajuda?"
              onClick={() => this.handleHelp()}
            >
              <img style={{ width: 26 }} alt="" src={help} />
            </button>
          </Recado>

          <ProdList id="prod-list" data-tour="step-0">
            <Loader
              id="load_list"
              visible={this.state.isLoading}
              type="ThreeDots"
              color="#0086c3"
            />

            {this.state.feed.map((prod) => (
              <ProdItem key={prod.id}>
                {this.state.mask_view === prod.id && (
                  <div className="mask" id={prod.id}>
                    <Loader
                      id="load_item"
                      visible={this.state.isLoading_item}
                      type="ThreeDots"
                      color="#fff"
                    />

                    {this.state.view_mnu_quantidade && (
                      <div id="mnuQt" onClick={(e) => this.handleEscape(e)}>
                        <h3>Escolha a quantidade: </h3>
                        <input
                          type="number"
                          min="1"
                          className="qt_item bt_alpha"
                          onChange={this.handleChange}
                          value={this.state.qt}
                          placeholder="Digite um número"
                        />
                        {prod.complementar !== 0 && (
                          <p className="mini-dica">
                            Aumentar a quantidade desse produto{" "}
                            <strong>
                              não permite escolher fotos complementares
                              diferentes
                            </strong>{" "}
                            para cada unidade. Para isso, coloque o produto duas
                            vezes no mesmo pedido.
                          </p>
                        )}

                        <button
                          className="bt_alpha addCart"
                          type="button"
                          onClick={() => this.handleAddCart(prod)}
                        >
                          <img
                            src={shop}
                            alt="Pedido"
                            className="icon32"
                            title="Colocar no pedido"
                          />
                        </button>

                        <button
                          className="bt_alpha addCancel"
                          type="button"
                          onClick={() => this.cancelAddCart()}
                        >
                          <img
                            src={trash}
                            className="icon32"
                            alt="Cancelar Item"
                            title="Não colocar no pedido"
                          />
                        </button>
                      </div>
                    )}
                  </div>
                )}

                <header>
                  <p className="prod-title">{prod.nome}</p>
                  <button
                    type="button"
                    onClick={() => this.handleConfig(prod.id)}
                    title="Configurar para colocar no pedido."
                  >
                    <img
                      src={params}
                      className="icon32"
                      alt="Medidas"
                      title="Configurar para colocar no pedido."
                    />
                  </button>
                </header>
                <img
                  className="img-produtos"
                  src={prod.caminho}
                  alt="Produtos"
                  onClick={() => this.handlePreview(prod.caminho)}
                />
                <footer>
                  <p>{stringToList(prod.descricao, "prod-desc")}</p>
                </footer>
              </ProdItem>
            ))}
          </ProdList>
        </div>
        <Tour
          steps={steps}
          isOpen={this.state.isTourOpen}
          onRequestClose={this.closeTour}
          showNavigationNumber={false}
          showNumber={false}
          rounded={3}
          disableInteraction={true}
          showNavigation={false}
          showCloseButton={false}
          onBeforeClose={() => localStorage.setItem("tour-produto", 1)}
          lastStepNextButton={
            <button className="icone-e-texto">Ok, entendi!</button>
          }
        />
      </React.Fragment>
    );
  }
}

export default Produtos;
