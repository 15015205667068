import React from "react";
import { PedItem } from "./styles";
import { moeda, databoa, iconOrderSelector } from "../../../services/utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faDolly,
  faCheck,
  faShippingFast,
  faHandHoldingUsd,
  faShoppingBasket,
  /* faTimes, */
  faBarcode,
  faDownload,
  faInfo,
  faWallet,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { notificar } from "../../../components/Notificador";
import GeraPagamento from "../../../components/GeraPagamento";
import api from "../../../services/api";

export default function OrderItem({ pedido, onClick }) {
  //TODO
  /* const handleExcluiPedido = (e, id) => {
    notificar(`Excluindo pedido ${id}.`, "vermelho", "embaixo");
  }; */
  const handleDadosDeposito = () => {
    notificar(
      "Você receberá um email em breve com nossos dados bancários para depósito."
    );

    api.get("pagamentos/dadosbancarios").then((ret) => {
      let cor = !ret.data.error ? "verde" : "vermelho";
      notificar(ret.data.msg, cor);
    });
  };

  const handlePacote = (id) => {
    notificar(`Preparando seu download...`, null, null, null, null, 2000);
    api
      .post("empacotar", { id }, { responseType: "arraybuffer" })
      .then((response) => {
        if (response.status === 204) {
          notificar(
            `Desculpe. Este pedido não possui fotos para download.`,
            "vermelho"
          );
          return 0;
        } else {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `Pedido-${id}.zip`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
      .catch((err) => {
        notificar(`Um erro ocorreu. Desculpe. ${err}`);
      });
  };

  return (
    <PedItem onClick={onClick}>
      <div className="actions">
        {/* {pedido.pedFinanceiro === null && (
          <FontAwesomeIcon
            className="icon-ped-act"
            icon={faTimes}
            title="Pedidos que ainda não foram pagos podem ser excluídos"
            onClick={(e) => handleExcluiPedido(e, pedido.pedId)}
          />
        )} */}
        {(pedido.pedFinanceiro !== null ||
          pedido.pedTotal - pedido.pedDesconto === 0) && (
          <FontAwesomeIcon
            className="icon-ped-act"
            icon={faDownload}
            title="Fazer download das imagens deste pedido"
            onClick={() => handlePacote(pedido.pedId)}
          />
        )}
        {pedido.pedFinanceiro === null &&
          pedido.pedPagamento.parcforma === 4 && (
            <FontAwesomeIcon
              className="icon-ped-act"
              icon={faWallet}
              title="Dados para depósito"
              onClick={() => handleDadosDeposito()}
            />
          )}
        <FontAwesomeIcon
          className="icon-ped-act"
          icon={faInfo}
          title={`Efetuado em ${databoa(pedido.pedData)}. Custo ${moeda(
            pedido.pedTotal
          )}. ${
            pedido.pedDesconto !== 0
              ? "Descontos " + moeda(pedido.pedDesconto) + "."
              : "Sem descontos."
          } ${
            pedido.pedFrete !== 0
              ? "Frete " + moeda(pedido.pedFrete) + "."
              : "Sem taxa de entrega."
          }`}
          onClick={() =>
            notificar(
              `O pedido ${pedido.pedId} foi efetuado em ${databoa(
                pedido.pedData
              )}. Custo ${moeda(pedido.pedTotal)}. ${
                pedido.pedDesconto !== 0
                  ? "Descontos " + moeda(pedido.pedDesconto) + "."
                  : "Sem descontos."
              } ${
                pedido.pedFrete !== 0
                  ? "Frete " + moeda(pedido.pedFrete) + "."
                  : "Sem taxa de entrega."
              }`,
              "amarelo",
              null,
              null,
              null,
              15000
            )
          }
        />
      </div>
      <div className="content">
        {pedido.pedId}
        <span className="order-details">
          {pedido.pedTotal - pedido.pedDesconto !== 0
            ? moeda(pedido.pedTotal - pedido.pedDesconto)
            : "Crédito"}
        </span>
        {pedido.pedFinanceiro === null &&
          pedido.pedPagamento.parcforma === 5 && (
            <GeraPagamento
              montar={pedido.pedPagamento}
              icone={faBarcode}
              via={2}
              texto={"Ver boleto"}
              forma={5}
            />
          )}

        {pedido.pedPagamento.parcforma === 2 &&
          pedido.pedPagamento.parctransacao &&
          pedido.pedPagamento.parcpagoem === null && (
            <a
              href={pedido.pedPagamento.parctransacao}
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                className="icon-ped-act"
                icon={faCreditCard}
                title="Pagar este pedido agora"
              />{" "}
              Pagar agora
            </a>
          )}
        <span className="order-state" title="Histórico deste pedido.">
          {iconOrderSelector("pedido", faShoppingBasket, pedido)}
          {iconOrderSelector("financeiro", faHandHoldingUsd, pedido)}
          {iconOrderSelector("processamento", faDolly, pedido)}
          {iconOrderSelector("ok", faShippingFast, pedido)}
          {iconOrderSelector("entregue", faCheck, pedido)}
        </span>
      </div>
    </PedItem>
  );
}
