import styled from "styled-components";

export const PedResumo = styled.div`
  .actions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid #ccc;
    .ped-act {
      color: #999;
      padding: 15px;
      border-radius: 3px;
      border: 1px solid #ccc;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .icon-ped-act {
        margin: 0px 10px;
        color: #999;
        z-index: 5;
        font-size: 1.5rem;
        cursor: pointer;
      }
      :hover {
        background-color: #ddd;
      }
    }
  }

  .content {
    background-color: #eee;
    :hover {
      background-color: #ddd;
    }

    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
  }
  .ped-history {
    font-size: 0.8em;
  }
  .icon-ped-type {
    margin-right: 10px;
    color: #666;
  }
  .icon-partial {
    margin-right: 10px;
    color: #bbb;
  }
  .icon-order {
    margin-bottom: 20px;
    font-size: 2rem;
    color: #bbb;
  }

  .ok {
    color: #00bf00;
  }

  .danger {
    color: #bf0000;
  }

  .pending {
    color: #666;
  }

  .processing {
    color: #fccc0f; /* #27a9f4; */
  }

  .icon-partial-separator {
    margin: 0 20px;
  }

  .partial-state {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 10%;
  }
  .order-state {
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-template-columns:
      minmax(100px, 1fr)
      minmax(100px, 1fr)
      minmax(100px, 1fr)
      minmax(100px, 1fr)
      minmax(100px, 1fr);
    border-bottom: 1px solid #ccc;
  }
  .order-state-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    :not(:last-child) {
      border-right: 1px solid #ccc;
    }
    :hover {
      background-color: #eee;
    }
  }
  table#order-details {
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-template-columns:
      minmax(100px, 0.3fr)
      minmax(100px, 3.2fr)
      minmax(100px, 0.5fr)
      minmax(100px, 0.5fr)
      minmax(100px, 0.5fr);

    thead,
    tbody,
    tfoot,
    tr {
      display: contents;
      :nth-child(even) td {
        background: #f8f6ff;
      }
      :first-child {
        border-top: 2px solid #ddd;
      }
    }

    th,
    td {
      border-bottom: 1px solid #ddd;
      padding: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    th {
      position: sticky;
      top: 0;
      background: #888;
      text-align: left;
      font-weight: 600;
      color: white;
    }

    th:last-child {
      border: 0;
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
      color: #808080;
    }
    img {
      max-width: 90%;
      margin: -5px;
      object-fit: cover;
      border-radius: 3px;
      border: 1px solid #ccc;
    }
    .no-print{
      display: none;
    }
  }
`;
