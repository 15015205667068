import React from "react";

import { Container } from "./styles";
import { databoa } from "../../../services/utils";
import { parseISO, sub, format } from "date-fns";

function RelatorioArquivos({ dados, formatura }) {
  const {
    FormFtInfancia,
    FormFtHomenagem,
    FormFtLed,
    FormData,
    FormLimiteEntrega,
  } = formatura;
  const qts = FormFtInfancia + FormFtHomenagem + FormFtLed;
  const lim = format(
    sub(parseISO(FormData), { days: FormLimiteEntrega }),
    "dd/MM/yyyy"
  );
  return (
    <Container>
      <h2>Relatório de arquivos desta turma</h2>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Arquivos</th>
          </tr>
        </thead>
        <tbody>
          {dados.financeiro.map((dt) => (
            <tr key={dt.AlCodigo}>
              <td>{dt.AlCodigo}</td>
              <td>{dt.AlNome}</td>
              <td>
                <ul>
                  {
                    `${
                      dados.uploads.filter((it) => it.user === dt.AlUser).length
                    } de ${qts + 1} carregados`
                    /* .map((up) => (
                      <li>{up.label || "Não definido"}</li>
                    )) */
                  }
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3}>
              {qts} fotos e 1 música por formando até {lim} - Gerado{" "}
              {databoa(null, true)}
            </td>
          </tr>
        </tfoot>
      </table>
    </Container>
  );
}

export default RelatorioArquivos;
