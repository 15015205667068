import React from "react";
import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Toast = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 5px;
  }
  .Toastify__toast--info {
    background: #444444cc;
  }

  .Toastify__toast--success {
    background: #298a08cc;
  }
  .Toastify__toast--warning {
    background: #dba901cc;
  }
  .Toastify__toast--error {
    background: #e11200cc;
  }
`;
export const notificar = (
  mensagem,
  cor,
  posicao,
  onOpen,
  onClose,
  autoClose
) => {
  const configs =
    posicao === "embaixo"
      ? { position: toast.POSITION.BOTTOM_CENTER }
      : { position: toast.POSITION.TOP_RIGHT };
  if (onOpen) {
    configs.onOpen = onOpen;
  }

  if (onClose) {
    configs.onClose = onClose;
  }

  if (autoClose) {
    configs.autoClose = autoClose;
  }
  switch (cor) {
    case "verde":
      toast.success(mensagem, configs);
      break;
    case "amarelo":
      toast.warn(mensagem, configs);
      break;
    case "vermelho":
      toast.error(mensagem, configs);
      break;
    default:
      toast.info(mensagem, configs);
  }
};
export default function Notificador() {
  return <Toast />;
}
