import React from "react";
import { BrowserRouter } from "react-router-dom";
import GlobalStyle, { Container } from "./styles/global";
import { Context } from "./context/Context";
import Notificador from "./components/Notificador";
import Modal from "react-modal";

import Routes from "./routes";
import Header from "./components/Header/Header";
Modal.setAppElement("#root");
function App() {
  return (
    <Context>
      <BrowserRouter>
        <Notificador />
        <GlobalStyle />
        <Container>
          <Header />
          <Routes />
        </Container>
      </BrowserRouter>
    </Context>
  );
}

export default App;
