import styled from "styled-components";

export const Container = styled.ul`
  list-style: none;

  li.frete-checked {
    border-color: #888;
    background-color: #eee;
    .icon-check {
      color: #00bf00 !important;
    }
    .custo-frete {
      display: none;
    }
    .valor-frete {
      display: block;
    }
  }

  li.frete-small {
    padding: 2px !important;
    .custo-frete,
    .descricao-frete,
    .valor-frete {
      display: none;
    }
  }

  li.frete-item {
    border: 1px solid #ccc;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    input {
      width: 50px;
    }
    :hover {
      border-color: #888;
      background-color: #eee;
    }
    p.custo-frete {
      font-size: 0.9em;
      font-style: italic;
      font-weight: 500;
    }

    p.valor-frete {
      font-weight: 600;
    }

    .icon-check {
      color: #ccc;
      margin: 20px;
    }
  }
`;
