import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import api from "../../services/api";

import { Recado } from "../../components/styled-components-global";
import { Form, Container } from "./styles";

class Reset extends Component {
  state = {
    email: "",
    token: "",
    password: "",
    msg: "",
    error: "",
    type: "",
  };

  componentDidMount(props) {
    this.setState({ token: this.props.match.params.tkn });
  }

  handleReset = async (e) => {
    e.preventDefault();
    const { email, password, token } = this.state;
    if (!email || !password) {
      this.setState({
        error: "Preencha seu e-mail e a nova senha para continuar!",
        type: "EMPTY",
      });
    } else {
      await api
        .post("/reset", { email, password, token })
        .then((response) => {
          this.setState({
            msg: "Sua senha foi recriada com sucesso. Redirecionando para o login...",
          });
          setTimeout(() => {
            this.props.history.push("/");
          }, 3000);
        })
        .catch((err) => {
          this.setState(err.response.data);
        });
    }
  };

  render() {
    return (
      <Container>
        <Form onSubmit={this.handleReset}>
          <h2>Recriação de Senha do Portal</h2>

          <span>Preencha seu email e a nova senha para seu usuário.</span>
          {this.state.msg && <Recado>{this.state.msg}</Recado>}

          {this.state.error && <p>{this.state.error}</p>}

          <input
            type="email"
            placeholder="Endereço de e-mail"
            onChange={(e) => this.setState({ email: e.target.value })}
            autoFocus
          />

          <input
            type="password"
            placeholder="Nova senha"
            onChange={(e) => this.setState({ password: e.target.value })}
          />

          {!this.state.msg && <button type="submit">Enviar</button>}
          <hr />
          <Link to="/signin" title="Lembrei a senha ou entrei por engano.">
            Não preciso mais recuperar a senha
          </Link>
        </Form>
      </Container>
    );
  }
}

export default withRouter(Reset);
