import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  input {
    padding: 10px;
    margin: 5px;
    border: 1px solid #555;
    border-radius: 4px;
    display: block;
    width: 100%;
  }
  svg {
    margin: 10px;
  }
`;
