import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import api from "../../services/api";

import { Recado } from "../../components/styled-components-global";
import { Form, Container } from "./styles";

class Forgot extends Component {
  state = {
    email: "",
    msg: "",
    error: "",
    type: "",
  };

  handleForgot = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    if (!email) {
      this.setState({
        error: "Preencha seu e-mail para continuar!",
        type: "EMPTY",
      });
    } else {
      await api
        .post("/forgot", { email })
        .then((response) => {
          this.setState({
            error: "",
            type: "",
            msg: "Sua solicitação foi enviada. Verifique o link em seu email para continuar o processo.",
          });
        })
        .catch((err) => {
          this.setState(err.response.data);
        });
    }
  };

  render() {
    return (
      <Container>
        <Form onSubmit={this.handleForgot}>
          <h2>Recuperação da Senha do Portal</h2>

          <span>Preencha seu email para receber a recuperação de senha.</span>
          {this.state.msg && <Recado>{this.state.msg}</Recado>}

          {this.state.error && <p>{this.state.error}</p>}

          <input
            type="email"
            placeholder="E-mail para recuperação de senha"
            onChange={(e) => this.setState({ email: e.target.value })}
            autoFocus
          />

          {!this.state.msg && <button type="submit">Enviar</button>}
          <hr />
          {!this.state.msg ? (
            <Link to="/signin" title="Lembrei a senha ou entrei por engano.">
              Não preciso mais recuperar a senha
            </Link>
          ) : (
            <Link to="/signin" title="Volta para o login do portal.">
              Voltar para o Login
            </Link>
          )}
        </Form>
      </Container>
    );
  }
}

export default withRouter(Forgot);
