import styled from "styled-components";

export const ParcItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .pay-history {
    font-size: 0.8em;
  }
  .icon-pay-type {
    margin-right: 10px;
    color: #666;
  }
  .icon-partial {
    margin-right: 10px;
    color: #bbb;
    padding: 3px;
    :hover {
      background-color: #ddd;
    }
  }
  .ok {
    color: #00bf00;
  }

  .danger {
    color: #bf0000;
  }

  .pending {
    color: #666;
  }

  .icon-partial-separator {
    margin: 0 20px;
  }

  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
  padding: 5px;

  :hover {
    background-color: #eee;
    cursor: pointer;
  }

  .partial-state {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 10%;
  }
`;
