import styled from "styled-components";

export const PedItem = styled.div`
  --deslocar: 0px;
  --largura: 5px;
  --shadow: 0;

  :hover {
    --deslocar: 10px;
    --largura: 60px;
    --shadow: 2px 1px 8px #999;
    background-color: #999;
    cursor: pointer;
  }
  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  .actions {
    overflow: hidden;
    position: relative;
    float: left;
    display: flex;
    width: var(--largura);
    transition: width 0.5s;
    align-items: center;
    justify-content: flex-start;
    z-index: 5;
    .icon-ped-act {
      margin: 10px 0 0 10px;
      color: #fff;
      z-index: 5;
      cursor: pointer;
      :hover {
        color: #fccc0f;
      }
    }
  }

  .content {
    background-color: #fff;
    :hover {
      background-color: #eee;
    }
    transform: translate(var(--deslocar));
    box-shadow: var(--shadow);
    transition: transform 0.5s, box-shadow 0.5s;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;

    .ped-history {
      font-size: 0.8em;
    }
    .icon-ped-type {
      margin-right: 10px;
      color: #666;
    }
    .icon-partial {
      margin-right: 10px;
      color: #bbb;
    }
    .icon-order {
      margin-right: 10px;
      color: #bbb;
    }

    .ok {
      color: #00bf00;
    }

    .danger {
      color: #bf0000;
    }

    .pending {
      color: #666;
    }

    .processing {
      color: #fccc0f; /* #27a9f4; */
    }

    .icon-partial-separator {
      margin: 0 20px;
    }

    .partial-state {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 10%;
    }
  }
`;
