import styled from "styled-components";

export const Pagina = styled.ul`
  text-decoration: none;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px;
  }
  li.item {
    border-bottom: 1px solid #c8e2f7;
    border-radius: 8px 8px 0 0;
    border-top: 1px solid #e0eff9;
    border-left: 1px solid #e0eff9;
    border-right: 1px solid #e0eff9;
    z-index: 8;
  }
  li.active {
    border-top: 1px solid #c8e2f7;
    border-left: 1px solid #c8e2f7;
    border-right: 1px solid #c8e2f7;
    border-radius: 8px 8px 0 0;
    box-shadow: 0px -3px 3px #c8e2f7;
    z-index: 10;
    font-weight: 600;
  }
  li:hover {
    background-color: #c8e2f7;
    transition: background-color 500ms;
  }
`;
