import React from "react";

import { CredContainer } from "./styles";
import { moeda, resumeCreditos } from "../../../services/utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";

function Creditos({ dados, cart }) {
  const { totalImpressas, totalDigitais } = resumeCreditos(dados, cart);
  return (
    <CredContainer>
      {((dados.AlCredFtDigital !== 0 || dados.AlCredFtImpressa !== 0) && (
        <div className="comCredito">
          <h4>
            <FontAwesomeIcon icon={faTags} /> Créditos:
          </h4>

          {dados.AlCredFtDigital > 0 && (
            <p>
              {totalDigitais.qt} de {dados.AlCredFtDigital} fotos digitais
            </p>
          )}

          {dados.AlCredFtImpressa > 0 && (
            <p>
              {totalImpressas.qt} de {dados.AlCredFtImpressa} fotos impressas
              {dados.FormDownImpressas === 1 && <span> com download</span>}
            </p>
          )}
          <p>Descontos: {moeda(totalDigitais.val + totalImpressas.val)}</p>
        </div>
      )) ||
        null}
    </CredContainer>
  );
}

export default Creditos;
