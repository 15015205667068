import React from "react";

import { Container, Title } from "./styles";

function Contact() {
  return (
    <Container>
      <Title>Contact</Title>
    </Container>
  );
}

export default Contact;
