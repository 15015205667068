import styled from "styled-components";

export const CredContainer = styled.div`
  .comCredito {
    background-color: #eee;
    padding: 10px;
    h4 {
      border-bottom: 1px solid #fff;
    }
    p {
      padding-top: 10px;
    }
    p.total-final {
      padding-bottom: 10px;
      font-weight: 600;
      text-align: center;
    }
  }
`;
