import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import api from "../../services/api";
import { login } from "../../services/auth";
import { StateContext } from "../../context/Context";

import { Recado } from "../../components/styled-components-global";
import { Form, Container } from "./styles";

class SignIn extends Component {
  static contextType = StateContext; //Cria um contexto dentro da classe
  //a chamada é this.context. ...

  state = {
    email: "",
    password: "",
    error: "",
    type: "",
    redirect: "/",
  };

  componentDidMount(props) {
    if (this.props.match.url.includes("resumo")) {
      this.setState({
        redirect: this.props.location.pathname.replace("/signin", ""),
      });
    } else {
      if (
        this.props.match.params.auto &&
        this.props.match.params.auto.indexOf("@") &&
        this.props.match.params.auto.indexOf("|")
      ) {
        let auth = this.props.match.params.auto.split("|");
        this.setState({ email: auth[0], password: atob(auth[1]) });
      }
    }
    //if (this.props.match.params.tipo && this.props.match.params.tipo.includes())
    sessionStorage.clear();
  }

  handleSignIn = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({
        error: "Preencha e-mail e senha para continuar!",
        type: "EMPTY",
      });
    } else {
      await api
        .post("/auth", { email, password })
        .then((response) => {
          login(response.data.token);
          this.context.actions.setUser(response.data.user);
          this.props.history.push(this.state.redirect);
        })
        .catch((err) => {
          this.setState(err.response.data);
        });
    }
  };

  render() {
    return (
      <Container>
        <Form onSubmit={this.handleSignIn}>
          <h2>Acesse o Portal do Formando</h2>
          <Recado>
            <span>
              Para acessar você precisa ter efetuado o{" "}
              <strong>Autocadastro</strong> com o link recebido da sua comissão.
              <br /> <u>Use o mesmo email</u> do seu Autocadastro.
            </span>
          </Recado>

          {this.state.error && <p>{this.state.error}</p>}

          <input
            type="email"
            placeholder="Endereço de e-mail"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
            autoFocus
          />

          <input
            type="password"
            value={this.state.password}
            placeholder="Senha"
            onChange={(e) => this.setState({ password: e.target.value })}
          />

          {this.state.error && this.state.type === "NOT_FOUND" && (
            <Recado>
              <span>
                Caso tenha certeza que já efetuou o autocadastro clique{" "}
                <Link to="/signup">
                  <strong>AQUI</strong>
                </Link>{" "}
                e crie o seu.
              </span>
            </Recado>
          )}

          <button type="submit">Entrar</button>
          <Link
            to="/forgot"
            style={{ marginTop: "10px", marginBottom: "-10px" }}
            title="Clique para recuperar a sua senha."
          >
            Esqueci minha senha
          </Link>
          <hr />
          <Link
            to="/signup"
            title="Já fiz o autocadastro mas ainda não tenho usuário do portal."
          >
            Ainda não tem usuário? Crie aqui.
          </Link>
        </Form>
      </Container>
    );
  }
}

export default withRouter(SignIn);
