import React, { useState, useEffect, useContext, useCallback } from "react";

import api from "../../../services/api";
import tiposFretes from "../../../services/fretes";
import { moeda } from "../../../services/utils";
import { StateContext } from "../../../context/Context";
import { SEDEX_PADRAO } from "../../../configs/consts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import frete from "../../../assets/icons/svg/flat/019-fast-delivery.svg";
import { Container } from "./styles";
import { notificar } from "../../../components/Notificador";

function Frete({ tamanho, aoClick }) {
  const context = useContext(StateContext);
  const temProduto = Boolean(
    context.state.cart.find((it) => it.tipo === "produto")
  );

  const [fretes, setFretes] = useState(
    temProduto
      ? tiposFretes.filter((it) => it.modalidade !== "entrega")
      : tiposFretes
  );
  const [sedex, setSedex] = useState(0);
  const [cep] = useState(context.state.cadastro.AlCEP);
  const [liberaSedex, setLiberaSedex] = useState(false);
  const [mark] = useState(
    context.state.checkout.frete.length > 0
      ? context.state.checkout.frete[0].slug
      : null
  );

  const calculaSedex = useCallback((dados, ft, tm) => {
    api.post(`/perfil/frete`, dados).then((dt) => {
      if (!dt.data.error) {
        clearTimeout(tm);
        ft[0].valor = parseFloat(
          dt.data[0].Valor.replace(".", "").replace(",", ".")
        );
        setFretes(ft);
        setSedex(ft[0].valor);
        setLiberaSedex(true);
      }
    });
  }, []);

  useEffect(() => {
    const dados = { cep, tamanho };
    let ft = fretes;
    //controle de região (porto alegre) para motoboy
    /* if (parseInt(dados.cep) > 91999999 || parseInt(dados.cep) < 91000000) {
      let ftf = ft.filter((it) => it.slug !== "motoboy");
      ft = ftf;
    } */
    if (!temProduto) {
      const tm = setTimeout(() => {
        ft[0].valor = SEDEX_PADRAO;
        setFretes(ft);
        setSedex(ft[0].valor);
        setLiberaSedex(true);
      }, 5000);
      calculaSedex(dados, ft, tm);
    }
  }, [cep, tamanho]);

  const handleFrete = (id) => {
    [...document.getElementsByName("radio-frete")].filter(
      (it) => it.value == id
    )[0].checked = true;
    if (liberaSedex || id > 0) {
      if (document.getElementsByClassName("frete-checked").length) {
        document
          .getElementsByClassName("frete-checked")[0]
          .classList.remove("frete-checked");
      }
      document
        .getElementsByName("tipo-frete")
        .forEach((it) => (it.className = "frete-item frete-small"));

      document.getElementById(id).className =
        "frete-item frete-checked";
      context.actions.setCheckout({
        frete: fretes.filter((it) => it.id === id),
      });
      aoClick();
    } else {
      notificar("O Sedex ainda está sendo calculado. Aguarde...", "amarelo");
    }
  };

  return (
    <Container>
      <h2>
        <img
          src={frete}
          className="icon32"
          alt="Escolher Frete"
          style={{ marginBottom: "-10px" }}
        />{" "}
        FORMA DE RETIRADA
      </h2>
      <p>Escolha uma forma de entrega/retirada que lhe atenda melhor</p>
      {fretes.map((frete) => (
        <li
          key={frete.id}
          id={frete.id}
          name="tipo-frete"
          className={
            mark === frete.slug ? "frete-item frete-checked" : "frete-item"
          }
          onClick={(e) => handleFrete(frete.id)}
          title={
            frete.id === 0 && liberaSedex === false
              ? "Calculando o frete. Aguarde..."
              : ""
          }
        >
          <input type="radio" name="radio-frete" value={frete.id} hidden />
          <FontAwesomeIcon icon={faCheck} className="icon-check" />
          <div>
            <h3>{frete.nome}</h3>
            <p className="descricao-frete">{frete.descricao}</p>
            {frete.valor > 0 && (
              <p className="valor-frete">
                {frete.id === 0
                  ? liberaSedex
                    ? moeda(sedex)
                    : "Calculando. Aguarde..."
                  : moeda(frete.valor)}
              </p>
            )}
            <p className="custo-frete">{frete.custo}</p>
          </div>
        </li>
      ))}
      {context.state.cart.find((it) => it.tipo === "produto") && (
        <p className="mini-dica">
          Como seu pedido <strong>contém produtos</strong>, serviços de entrega
          não estão disponíveis. Apenas retirada nas nossas unidades.
        </p>
      )}
    </Container>
  );
}

export default Frete;
