import styled from "styled-components";

export const Gboleto = styled.div`
  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .via-1 {
      background-color: #eee;
      padding: 10px;
      #menuForma {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
    .via-2 {
      padding: 0;
      background-color: transparent;
    }
  }
  input,
  select {
    padding: 10px;
    margin: 5px;
    border: 1px solid #555;
    border-radius: 4px;
    text-align: center;
  }
  .instrucoes {
    font-size: 0.8em;
  }
`;
