import styled from "styled-components";

export const CtrItem = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  .contract-title {
    font-weight: 500;
    padding: 1px;
    border-bottom: 1px solid #ccc;
    .icon-contract-title {
      margin-right: 5px;
    }
    a {
      font-size: 0.8em;
    }
  }
  .contract-description {
    font-size: 0.8em;
  }
  .contract-footer {
    padding: 5px;
  }
`;

export const ExtrasItem = styled.p`
  display: flex;
  justify-content: space-between;
  .icon-extras {
    margin-right: 10px;
  }

  :not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
  padding: 5px;

  :hover {
    background-color: #eee;
    cursor: pointer;
  }
`;
